import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import contentParser from "gatsby-wpgraphql-inline-images";

import parse from "html-react-parser";

import { inlineUrls } from "../utils";

import Layout from "../layouts/home";
import SEO from "../components/SEO";

import Social from "../components/Social/sharer";

class SinglePost extends React.Component {
  renderPreviousLink = () => {
    const {
      pageContext: { prev },
    } = this.props;

    if (!prev) {
      return null;
    }
    return (
      <Link
        to={`/${prev.uri}`}
        className="pagination-previous"
        title={prev.title}
      >
        <span className="icon-left-open-mini"></span>
      </Link>
    );
  };

  renderNextLink = () => {
    const {
      pageContext: { next },
    } = this.props;

    if (!next) {
      return null;
    }
    return (
      <Link to={`/${next.uri}`} className="pagination-next" title={next.title}>
        <span className="icon-right-open-mini"></span>
      </Link>
    );
  };
  render() {
    const {
      location,
      data: {
        wordPress: {
          post: {
            title,
            acf_blog_author: { author, jobTitle },
            excerpt,
            content,
            featuredImage,
          },
        },
      },
    } = this.props;

    // console.log(
    //   this.props.data.wordPress.casestudy.acf_case_study_images.visuals
    // );

    return (
      <Layout location={location} cssClass="post">
        <SEO title={title} description={excerpt} />
        <section id="header" className="p-top p-bottom text-center bg-pink">
          <div className="featured">
            <h1>{title}</h1>
            <div className="lead">
              <p>
                {author}, {jobTitle}
              </p>
            </div>
          </div>
        </section>
        <section id="excerpt" className="bg-light-gray p-top p-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 lead line text-center">
                {parse(excerpt)}
              </div>
            </div>
          </div>
        </section>
        <Social location={location} />
        <section
          id="body"
          className="bg-light-gray b-top b-bottom mt-0 relative"
        >
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8 text-center mt-5 mb-5">
                {featuredImage && featuredImage.imageFile && (
                  <Img
                    alt={featuredImage.altText}
                    fluid={featuredImage.imageFile.childImageSharp.fluid}
                  />
                )}
              </div>
              <div className="offset-md-2 col-md-8 p-bottom">
                {contentParser({ content }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>

        <section id="pagination" className="bg-light-gray p-bottom mt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 pagination">
                <ul>
                  <li>{this.renderPreviousLink()}</li>
                  <li>{this.renderNextLink()}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default SinglePost;

export const singlePost = graphql`
  query GET_Post($id: ID!) {
    wordPress {
      post(id: $id) {
        postId
        title
        acf_blog_author {
          author
          jobTitle
        }
        excerpt
        content
        featuredImage {
          ...PostImage
        }
      }
    }
  }
`;

export const PostImage = graphql`
  fragment PostImage on WordPress_MediaItem {
    altText
    sourceUrl
    content
    mediaItemId
    modified
    imageFile {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
